import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax"
import Header from "./Header"
import "./layout.css"
import "semantic-ui-css/semantic.min.css"
import { Breadcrumb } from "semantic-ui-react"
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image'
import Footer from "./Footer";

const Layout = ({
  children,
  imageSrc = false,
  title = false,
  breadcrum = false,
  parallax= true
}) => {
  
  const data = useStaticQuery(pageQuery)
  const image =
    imageSrc ||
    data.colors.file.url

  const content = (title || breadcrum) && (
    <Fragment>
      <div style={{ marginTop: 120 }}>
      {breadcrum && (
        <div className="breadcrum">
          <Breadcrumb
            size="large"
            divider="/"
            sections={breadcrum.map(item => ({
              key: item.name,
              content: item.name,
              link: item.link,
            }))}
          / >
        </div>
      )}
      {title && <h1 className="title">{title}</h1>}
      </div>
    </Fragment>
  )

  return (
    <ParallaxProvider>
      <Header />
      { parallax &&
      <ParallaxBanner
        layers={[
          {
            image,
            amount: 0.7,
          },
        ]}
      >
        <div className="parallaxBodyContent">
          {content || (
            <div>
              <Img fadeIn={false} className="homePage-data-logo" fixed={data.logo.fixed} />
              <h2 className="homePage-data-text">Pedagogia creativa i educació a través de l'art</h2>
            </div>
          )}
        </div>
      </ParallaxBanner>
      }
      <main style={{ width: '100%'}}>{children}</main>
      <Footer />

    </ParallaxProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

export const pageQuery = graphql`
  query {
    colors: contentfulAsset (title: { eq: "colors" }){
      file {
        url
      }
    },
    logo: contentfulAsset (title: { eq: "logo-blanc" }){
      fixed (width: 500) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
  }
`
