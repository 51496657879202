import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const INTRANET_PAGES = graphql`
  query Intranet {
    pagines: allContentfulIntranet(sort: {fields: ordre, order: ASC}) {
      edges {
        node {
          nom
          slug
        }
      }
    }
  }
`

const Access = () => {
  const data = useStaticQuery(INTRANET_PAGES)
  return data.pagines
    ? data.pagines.edges.map(({ node }) => (
        <div key={node.slug} style={{ textAlign: "center" }}>
          <Link to={`intranet/${node.slug}`}>
            <div
              className="tagline-link button button-small menu-contrast-button"
              id={node.slug}
            >
              {node.nom}
            </div>
          </Link>
          <br />
        </div>
      ))
    : null
}

export default Access
